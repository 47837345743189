<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="404-not-found">
      <div v-if="$matchMedia.sm"
           :style="{marginTop: '-40px',height: '40vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%',
           backgroundImage: 'url('+require('@/assets/graphics/login.jpg')+')'}"></div>
      <TwoColumns image="login">
        <template v-slot:text-content>
          <div class="row  mb-m">
            <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1">
              <h3>{{ $t('error.404.label') }}</h3>
              <h5>{{ $t('error.404.message') }}</h5>
              <p class="pb-s" v-html="$t('error.404.paragraph')"></p>
              <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }">
                <Button :href="href" @click.native="navigate" class="-orange">{{ $t('headerBar.buttons.login.label') }}</Button>
              </router-link>
            </div>
          </div>
        </template>
        <template v-slot:image-content></template>
      </TwoColumns>
    </main>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';
import TwoColumns from '@/components/elements/TwoColumns.vue';
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';

export default {
  name: 'NotFound',
  components: {
    Button,
    TwoColumns,
    HeaderBarPublic,
  },
  metaInfo() {
    return {
      title: this.$t('error.404.label'),
    };
  },
};
</script>

<style lang="scss" src="@/sass/06_atoms/link.scss"></style>
